@import "design/variables";
@import "design/template/mixin_templates";

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display: block;
}

audio[controls], canvas, video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

html {
    font-size: 100%;
    overflow-y: scroll;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-size: 13px;
    line-height: 1.231;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    box-sizing: border-box;
}

body, button, input, select, textarea {
    font-family: sans-serif;
}

iframe {
    max-width: 100%;
}

@include titreBaseSize();

::-moz-selection {
    text-shadow: none;
}

::selection {
    text-shadow: none;
}

a:hover, a:active, a:focus {
    outline: 0;
}

img {
    max-width: 100%;
    height: auto !important;
    border: 0;
    -ms-interpolation-mode: bicubic;
}

ul, ol {
    margin: 1em 0;
    padding: 0 0 0 40px;
}

nav ul, nav ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

form {
    margin: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

legend {
    border: 0;
    *margin-left: -7px;
    padding: 0;
}

label {
    cursor: pointer;
}

button, input, select, textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
}

button, input {
    line-height: normal;
    *overflow: visible;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
}

input[type="search"] {
    box-sizing: content-box;
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* Remove default vertical scrollbar in IE6/7/8/9 */
textarea {
    overflow: auto;
    vertical-align: top;
}

.protectionpass {
    margin: 15px auto;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1, .h1 {
    font-size: 1.2em;
}

h2, .h2 {
    font-size: 1.1em;
}

h3, .h3 {
    font-size: 1.0em;
}

.title_section, h2, .h2 {
    font-size: 1.2em;
    margin: 0 0 10px -20px;
    padding: 10px 20px;
    width: 100%;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    box-sizing: content-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

/* Boutons */

.links_p .addmsglo, .links_p .button:not(.zoom), .links_p .bouton, .links_p input[type="submit"],
.template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a,
.accueil_diaporama_template .slide .slide-text .slider-see-more, .wrapper button,
#block-flottant-search button,
.template-panier .bouton, .produits .addbasket {
    background-image: url("../images/imgd513series/gradiant-btn.png");
    background-size:auto 100%;
    background-position: 0 bottom;
    background-repeat: repeat-x;
    border-radius: 5px 5px 5px 5px;
    font-size: 11px;
    font-weight: bold;
    padding: 8px 16px;
    position: relative;
    text-decoration: none;
    margin: 10px 0;
    border: none;
    display: inline-block;
    vertical-align: middle;
    &:hover {
        background-position: 0 0;
        background-size:auto 100%;
    }
}

.wrapper .search-bar-icon button{
    margin:0;
    border-radius:0;
}

/* CONTENEUR
   ================================================== */

#main-conteneur {
    max-width: 990px;
    width: 100%;
    margin: 20px auto;
    border-radius: 3px;

    display: flex;
    flex-flow: row wrap;

    /*> * {
        flex: 1 100%;
    }*/

    &::before, &::after{
        flex: 1 100%;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

.wrapper-content {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    &::before{
        content:'';
        display: block;
        height: 100%;
        position:absolute;
        width: 250px;
        top:0;
        left: 0;
        z-index: 0;
        border-radius:3px 0 0 0;
    }
    .wrapper, .accueil_diaporama_template {
        width: calc(100% - 251px) !important;
        float: right;
        clear: none;
        position: relative;
        z-index: 1;
    }
    &::after {
        content: '';
        position: absolute;
        width: calc(100% - 250px) !important;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 0;
        border-left-width: 1px;
        border-left-style: solid;
    }
}

#tinymce.wrapper-content::after{
    display: none;
}

.wrapper {
    width: 100%;
    position: relative;
    padding: 0 20px 10px;
}

.header_p + #wrapper {
    margin: -3px auto 30px;
}

/* HEADER
   ================================================== */

header {
    position: relative;
    width: 100%;
    margin: 0;
    z-index: 1000;
    border-radius: 3px 0 0 0;
}

#blocContent, .cmonsite-panier-3 #blocContent {
    position: absolute;
    top: 0;
    left: 0;
    height: 200px;
    width: 250px;
}

.cmonsite-panier-3 #main-conteneur header{
    z-index: 10000;
}

#headercontent {
    height: 200px;
    position: relative;
    width: 250px;
    text-align: center;
}

#logo {
    display: inline-block;
    //position: absolute;
    max-width: 100%;
    text-align: center;
    img {
        max-height: 180px;
    }
}

.slogan {
    display: block;
    //position: absolute;
    //position:relative;
    top: 10px;
    max-width: 100%;
    width: 100%;
    text-align: center;
    z-index: 10;
}

/* NAVIGATION
   ================================================== */

#nav-secondary, #nav-principal {
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    &:empty{
        border-width:0 !important;
    }
}

.dropDown {
    position: absolute;
    right: 0;
    top: 0;
    height: 41px;
    width: 41px;
    display: block !important; // Utile pour passer au-dessus du php et du JS
    cursor: pointer;
    &::before {
        content: '\f107';
        font-family: fontAwesome, sans-serif;
        font-size: 20px;
        width: 100%;
        line-height: 42px;
        display: inline-block;
        text-align: center;
        transform-origin: center center;
    }
}

.sousmenu_ouvert > .dropDown::before {
    transform: rotate(180deg);
}

.wrapper-content > .menu-secondary {
    position: relative;
    width: 250px;
    float: left;
}

.cmonsite-panier-4 #nav-secondary > ul{
    max-width: 100%;
    float: none;
}

.cmonsite-panier-3 #nav-secondary{
    margin-top:260px;
}
#nav-secondary {
    margin-top: 200px;

    li {
        position: relative;
        display: block;
        padding: 0;
    }

    a {
        text-decoration: none;
        display: block;
        font-size: 1.1rem;
        padding: 10px 20px;
    }

    > ul > li > a {
        font-weight: bold;
    }

    ul {
        position: relative;
        ul {
            display: none;
            a::before {
                content: "\f101";
                font-family: FontAwesome, sans-serif;
                font-size: 20px;
            }
        }
    }
}

.menu-principal{
    &::after{
        content:'';
        display: block;
        clear: both;
    }
}

.menu-principal.navigation_p{
    width: calc(100% - 250px);
    float: right;
    border-radius:0 3px 0 0;
    position: relative;
    z-index: 1051;
}

#nav-principal {
    width: 100%;
    position: relative;
    z-index: 1050;
    > ul {
        display: block;
        font-size: 0;
        padding: 0 0 3px 0;
        text-align: left;
        margin: 0;
        list-style: none;
        border-left-width: 1px;
        border-left-style: solid;
        ul {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            width: 200px;
            li {
                display: block;
                width: 100%;
            }
            ul {
                left: 100%;
                top: 0;
            }
        }
        > li {
            margin-left: 3px;
            margin-top: 3px;
        }
    }
    li {
        position: relative;
        display: inline-block;
        text-transform: none;
        margin: 0;
        font-size: 1rem;
        &.hasDropdown {
            > a {
                padding: 10px 30px 10px 15px;
            }
        }
    }
    a {
        display: block;
        font-size: 13px;
        font-weight: bold;
        text-decoration: none;
        padding: 10px 15px;
    }

    span.dropDown {
        width: 25px;
        &::before {
            text-align: left;
        }
    }

    &::before{
        border-left-width:0;
        border-left-style:solid;
        border-bottom-width:0;
        border-bottom-style:dotted;
    }
}

@media #{$large-and-up}{
    #nav-principal .dropDown {
        display: none !important; //Utile pour passer au dessus du php et JS
    }
    #nav-principal li.hasDropdown {
        > a {
            &::after {
                position: absolute;
                right: 0;
                top: 0;
                height: 36px;
                width: 30px;
                display: block;
                content: '\f107';
                font-family: fontAwesome, sans-serif;
                font-size: 20px;
                color: inherit;
                background: transparent;
                line-height: 36px;
                text-align: center;
                transform-origin: center center;
            }
        }
        li.hasDropdown > a::after {
            content: "\f105";
        }
        &:hover > a::after {
            transform: rotate(180deg);
        }
    }
}

/* FIL D'ARIANE
   ================================================== */

.nav-static-breadcrumb {
    border-style: dotted solid;
    border-width: 0 0 1px 1px;
    margin: 0 0 0 -21px;
    padding-right: 40px;
    box-sizing: content-box;
    width: 100%;
    > ul {
        position: relative;
        width: 100%;
        margin: 0;
        text-align: left;
        li {
            display: inline-block;
            font-size: 1rem;
            text-align: left;
            margin: 0 10px 0;
            padding: 0;
            position: relative;
            box-sizing: border-box;
            float: none;
            width: auto;
            max-width: 100%;
            &::before,
            &::after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                z-index: 0;
                transition: border-color .2s linear;
            }
            &::before {
                left: -20px;
                border: 20px solid rgba(0, 0, 0, .2);
                border-left-color: transparent;
                border-right-width: 0;
            }
            &::after {
                right: -20px;
                border-width: 20px 0 20px 20px;
                border-style: solid;
                border-color: transparent transparent transparent rgba(0, 0, 0, .2);
            }
            &:not(:last-child) {
                &::after {
                    margin: 0;
                }
            }
            &:first-child {
                margin-left: 0;
                &::before {
                    display: none;
                }
            }
            &:hover, &:last-child {
                a::before {
                    background-color: rgba(255, 255, 255, .2);
                }
                &::before {
                    border-color: rgba(255, 255, 255, .2) transparent rgba(255, 255, 255, .2) transparent;
                }
                &::after {
                    border-color: transparent transparent transparent rgba(255, 255, 255, .2);
                }
            }
        }
        a {
            line-height: 40px;
            text-decoration: none;
            display: block;
            padding: 0 10px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: rgba(0, 0, 0, .2);
                transition: background .2s linear;
            }
            > span {
                position: relative;
                z-index: 2;
            }
        }
    }
}

/* DIAPORAMA
   ================================================== */

%addcommentaires {
    background: #333 url("../images/imgd513series/comm.png") no-repeat 0 0;
    height: 15px;
    border-radius: 3px;
    overflow: hidden;
    padding: 0 0 0 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    left: auto;
    bottom: auto;
    text-decoration: none;
    font-size: 18px;
    line-height: 15px;
    z-index: 20;
    display: block;
    opacity: 1;
    transition: all .25s linear;
    color: #fff;
    border: 5px solid #333;
    width: auto;
    box-sizing: content-box;
    &:hover {
        background-position: 0 -31px;
        background-color: #fff;
        color: #333;
        border-color: #fff;
    }
    i {
        display: none;
    }
}

.bx-wrapper,
.accueil_diaporama_template .bx-wrapper,
.accueil_boutique_template_1 .bx-wrapper {
    @at-root #fiche-produit .previews .next, #fiche-produit .previews .previous, & a.bx-next, & a.bx-prev {
        background-image: none;
        width: 30px;
        height: 30px;
        border-style: solid;
        border-width: 2px;
        box-sizing: border-box;
        text-indent: -9999px;
        overflow: hidden;
        transition: all .2s linear;
        &::after {
            content: '';
            display: block;
            line-height: 26px;
            text-align: center;
            font-family: fontAwesome, sans-serif;
            font-size: 20px;
            font-weight: bold;
            text-indent: 0;
            position: absolute;
            top: -1px;
            width: 100%;
            transition: all .2s linear;
        }
    }
    @at-root #fiche-produit .previews .next, & a.bx-next {
        &::after {
            content: "\f105";
        }
    }
    @at-root #fiche-produit .previews .previous, & a.bx-prev {
        &::after {
            content: "\f104";
        }
    }

    @at-root #fiche-produit .bx-pager-item, & .bx-pager-item {
        .bx-pager-link {
            border-radius: 0;
            border: 2px solid;
            transition: all .2s linear;
        }
    }
}

.accueil_diaporama_template {
    padding-bottom: 0;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    .bx-wrapper {
        a.bx-next, a.bx-prev {
            width: 50px;
            height: 50px;
            border-width: 3px;
            opacity: 0;
            &::after {
                top: 0;
                line-height: 42px;
                font-size: 35px;
            }
        }
        .bx-start, .bx-stop {
            display: none;
        }
        .bx-pager {
            bottom: -22px;
        }
    }
    #bx-pager {
        margin-bottom: 0;
    }
    .slide {
        .content-slide-text {
            opacity: 0;
            transition: all .2s linear;
            > p {
                bottom: 0;
                width: 100%;
                left: 0;
                font-size: 16px;
                margin: 0;
                padding: 15px;
            }
            a.addcommentaires {
                @extend %addcommentaires;
            }
            a.slider-see-more {
                top: 35px;
                right: 10px;
                left: auto;
                position: absolute;
                bottom: auto;
            }
        }
        &:hover {
            .content-slide-text {
                opacity: 1;
            }
        }
    }
    &:hover {
        .bx-wrapper {
            a.bx-next, a.bx-prev {
                opacity: 1;
            }
            a.bx-next {
                right: 20px;
            }
            a.bx-prev {
                left: 20px;
            }
        }
    }
    &.accueil_diaporama_template_2 {
        margin-bottom: 0;
        .bx-wrapper {
            margin-bottom: 35px;
        }
    }
    &.accueil_diaporama_template_3 {
        .bx-wrapper {
            .bx-controls-auto {
                display: none;
            }
        }
        #bx-pager {
            padding: 10px 20px;
            width: 100%;
        }
    }
    &.accueil_diaporama_template_4 {
        margin: 0;
    }
    &.accueil_diaporama_template_5 {
        padding: 0;
        .bx-wrapper {
            margin-bottom: 0;
        }
    }
    &.accueil_diaporama_template_6 {
        padding: 0;
    }
}

/* ALBUM PHOTO
   ================================================== */

.template_album {
    .galerie {
        position: relative;
        float: left;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
        transition: box-shadow 0.25s linear;
        border-radius: 3px;

        border-bottom-style: solid;
        border-left-style: solid;
        border-right-style: solid;
        border-bottom-width: 1px;
        border-left-width: 1px;
        border-right-width: 1px;

        img {
            border-radius: 3px 3px 0 0;
        }

        a.addcommentaires{
            @extend %addcommentaires;
        }

        .commentP {
            font-size: 0;
            line-height: 0;
            font-weight: bold;
            padding: 0;
            border-radius: 0 0 3px 3px;
            z-index: 19;
            height: auto;

            position: absolute;
            opacity: 0;
            bottom: 0;
            left: 0;
            top: auto;
            border-top-style: dotted;
            border-top-width: 1px;
            transition: opacity .25s linear;

            transform:none;

            p {
                margin: 0;
                min-height: 15px;
                font-size: 11px;
                text-align: left;
                padding: 10px;
                line-height: initial;
            }

            &::after {
                content: '';
                display: block;
                clear: both;
            }
        }

        .center-img::after {
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 10;
            opacity: 0;
            transition: opacity 0.25s linear;
            border-radius: 3px 3px 0 0;
            background: url("../images/imgd525series/zoom.png") no-repeat center center;
            content: '';
            width: 100%;
            height: 100%;
        }

        &:hover {
            box-shadow: 1px 1px 20px rgba(0, 0, 0, .5);
            .center-img::after {
                opacity: 0.6;
            }
            .commentP {
                opacity: 1;
            }

        }
    }

    &.template_album_1 {
        .galerie {
            border: none;
        }
    }
    &.template_album_2,
    &.template_album_3 {
        .galerie{
            border: none;
            border-radius: 0;
            img{
                border-radius:0;
            }
        }
    }
    &.template_album_4, &.template_album_5 {
        .galerie {
            .commentP {
                position: relative;
                bottom: auto;
                display: block;
                opacity: 1;
            }
        }
    }
}

/* BOUTIQUE
   ================================================== */

.produit_etiquette, .produit_etiquette2 {
    width: 100%;
    position: absolute;
    height: 40px;
    font-size: 0;
    display: block;
    &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }
    h3, .h3 {
        display: inline-block;
        vertical-align: middle;
        padding: 8px 0;
        width: 100%;
        font-size: .9rem;
    }
}

//Remise produit
.remise, .inside-remise, .sub-remise, #fiche-produit .remise-produit {
    border-radius: 0;
    font-weight: bold;
    position: absolute;
    left: 0;
    z-index: 100;
    top: 0;
    padding: 0;
    box-sizing: border-box;
    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: inherit;
        color: inherit;
        transform-origin: center center;
    }
    &::before {
        transform: rotate(60deg);
    }
    &::after {
        transform: rotate(30deg);
    }
}

.remise, .remise-produit {
    left: auto;
    right: 0;
    transform: rotate(25deg);
}

.text-remise {
    position: absolute;
    text-align: center;
    z-index: 15;
    background: transparent;
}

.inside-remise {
    color: inherit;
    background: transparent;
}

.sub-remise {
    z-index: 10;
    text-align: center;
    &::before, &::after {
        z-index: 10;
    }
}

//Avec variable

@mixin star($el:'.remise', $star-size:40px, $star-decalage:6px) {
    #{$el} {
        width: $star-size;
        height: $star-size;
        &::before, &::after {
            width: $star-size;
            height: $star-size;
        }
    }
    #{$el} .text-remise {
        width: $star-size;
        height: $star-size;
        top: -$star-decalage;
        left: -$star-decalage;
        line-height: $star-size;
        font-size: $star-size / 10 + $star-size / 4;
    }
    $star-size: $star-size - $star-decalage;
    #{$el} .inside-remise {
        width: $star-size;
        height: $star-size;
        top: $star-decalage / 2;
        left: $star-decalage / 2;
        box-shadow: 0 0 0 $star-decalage / 3 #fff inset;
        &::before, &::after {
            width: $star-size;
            height: $star-size;
            box-shadow: 0 0 0 $star-decalage / 3 #fff inset;
        }
    }
    $star-size: $star-size - $star-decalage * 2 / 3;
    #{$el} .sub-remise {
        width: $star-size;
        height: $star-size;
        top: $star-decalage / 3;
        left: $star-decalage / 3;
        &::before, &::after {
            width: $star-size;
            height: $star-size;
        }
    }
}

.template_boutique_all {
    .produits {
        position: relative;
        top: 0;
        border-style: solid;
        border-width: 1px 1px 2px 1px;
        border-radius: 3px 3px 3px 3px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
        transition: box-shadow .1s linear, top .1s linear;

        @include star();
        .remise {
            left: auto;
            right: 0;
        }

        .productImageWrap {
            background: none;
            a {
                display: block;
            }
        }

        .nomprod {
            border-bottom-style: dotted;
            border-bottom-width: 1px;
            padding: 5px;
            font-weight: bold;
            margin-top: 0;
            text-align: center;
        }
        .prix {
            margin: 10px 0;
            text-align: center;
            font-size: 1.2rem;
            .prixprod, .oldprix {
                text-align: center;
                display: inline-block;
                vertical-align: middle;
                margin: 0 5px;
                float: none;
            }
            .prixprod {
                text-align: center;
            }
            .oldprix {
                text-decoration: line-through;
                font-size: 0.7em;
            }
        }
        .desc {

            font-size: 0.9em;
            padding: 0 10px;
        }
        .productContentWrap {
            padding: 0 5px;
        }
        .zoom {
            display: block;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.25s linear;
            background-image: url("../images/imgd525series/zoom.png");
            background-repeat: no-repeat;
            background-position: center center;

            &::before {
                display: none;
            }
            &:hover {
                opacity: .6;
            }
        }
        .addbasket {
            display: inline-block;
            font-weight: bold;
            text-align: center;
            margin: 10px 0;
            &:hover {
                cursor: pointer;
            }
        }
        .hors-stock {
            display: inline-block;
            margin: 10px 0;
            text-align: center;
            height: auto;
        }
        .attr {
            text-align: center;

        }
        select {
            border-radius: 3px 3px 3px 3px;
            border-width: 1px;
            border-style: solid;
            display: inline-block;
            font-size: 15px;
            height: 25px;
            margin: 0;
            padding: 2px 0;
        }

        &.is_etiquette .remise {
            top: 40px;
            right: 0;
        }

        &:hover {
            box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
            top: -3px;
        }
    }
}

//Template produit
.template_boutique {
    .produits {
        .nomprod {
            box-sizing: content-box;
            width: 100%;
            margin-left: -5px;
        }
    }
    &.template_boutique_1 .produits {
        .addbasket, .hors-stock, .attr {
            position: static;
            display: inline-block;
            float: none;
            margin: 10px 2.5%;
            vertical-align: middle;
        }
        .addbasket, .hors-stock {
            font-size: 0;
            width: auto;
            max-width: 90%;
            &::before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }
            > div {
                display: inline-block;
                vertical-align: middle;
                font-size: 11px;
            }
            &:not(.next-select) {
                width: 95%;
            }
        }
        .productContentWrap {
            font-size: 0;
        }
        .note-produit {
            font-size: 1rem;
        }
    }
    &.template_boutique_2 .produits {
        .productContentWrap {
            text-align: center;
        }
        .attr select {
            position: static;
        }
        .addbasket, .hors-stock {
            float: none;
            display: inline-block;
        }
        .prix .prixprod, .prix .oldprix {
            display: inline-block;
            float: none;
        }
    }
    &.template_boutique_3 .produits {
        .remise {
            top: 10px;
            right: 5px;
            left: auto
        }
        .container-etiquette {
            position: absolute;
            z-index: 10;
            left: 10px;
            padding-right: 6px;
        }
        .nomprod {
            line-height: 25px;
            box-sizing: border-box;
            margin-left: 0;
        }
        &.is_etiquette {
            .nomprod {
                margin-top: 0;
            }
        }
        .desc {
            margin: 20px 0;
        }
        .prix {
            text-align: left;
        }
        .attr {
            text-align: left;
            select {
                float: none;
            }
        }
        .addbasket {
            bottom: -10px;
        }
        .productContentWrap {
            margin-bottom: 10px;
        }
    }
    &.template_boutique_4 .produits {
        .produit_etiquette {
            position: relative;
        }
        .produit_etiquette::after{
            display: none;
        }
        .attr {
            select {
                width: 100%;
            }
        }
        .productContentWrap {
            text-align: center;
        }
        .addbasket, .hors-stock {
            width: 100%;
        }
        .desc {
            text-align: left;
        }
        &.produit_galerie_border_p .productContentWrap .attr select {
            left: auto;
        }
        &.is_etiquette .remise {
            top: 0;
        }
    }
    &.template_boutique.template_boutique_5 .produits {
        .note-produit {
            float: right;
            text-align: center;
            width: 45%;
            &__nb, &__stars {
                display: block;
            }
        }
        .productContentWrap {
            padding: 0;
        }
        .remise {
            top: auto;
            bottom: 10px;
        }
        .nomprod {
            width: 45%;
            box-sizing: border-box;
            margin-left: 0;
            overflow: hidden;
        }
        .prix {
            .prixprod {
                font-size: 1rem;
            }
        }
        .container-etiquette {
            h3, .h3 {
                font-size: 12px;
            }
        }
        .productImageWrap {
            min-height: 0;
            margin-bottom: 45px;
        }
        .addbasket, .hors-stock, .attr {
            width: 100%;
            clear: both;
            float: none;
            display: inline-block;
        }
    }
}

//Produits page d'accueil
.template_boutique_accueil {
    .produits {
        text-align: center;
        .prix, .attr, .addbasket, .hors-stock {
            display: inline-block;
            width: 90%;
            float: none;
            vertical-align: middle;
        }
        .addbasket {
            padding: 8px 6px;
        }
    }
    &.accueil_boutique_template_1 {
        .bx-viewport {
            height: auto !important;
            padding: 20px 0 20px;
        }
        @include produitAccueilTaille(70px, 3, 20px);
        .produits {
            .produit_etiquette h3,
            .produit_etiquette .h3 {
                font-size: 12px;
            }
            .remise {
                right: 7px;
                top: 7px;
            }
            &.is_etiquette .remise {
                top: 40px;
            }
        }
    }
}

/*-----------------------------
LIVRE D'OR
-------------------------------*/

#connexion-inscription h2,
#connexion-inscription .h2 {
    margin-left: 0;
    width: auto;
}

.message_lo {
    border-radius: 3px 3px 3px 3px;
    float: left;
    margin: 20px 0 20px 20px;
    position: relative;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    width: 670px;
    transition: box-shadow 0.25s linear;
    h2.sous-titre,
    .h2.sous-titre {
        border-bottom-style: dotted;
        border-bottom-width: 1px;
        border-radius: 3px 3px 0 0;
        margin: 0;
        padding: 10px 10px 10px 20px;
        font-size: 1em;
        width: auto;
    }
    .note {
        border-left-width: 1px;
        border-left-style: solid;
        border-radius: 0 3px 0 0;
        margin: 0;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 0;
    }
    .web {
        margin: 20px 0 20px 10px;
        padding: 0 10px;
    }
    .blcFleche {
        transition: top .2s linear;
    }
    .button {
        margin: 0 0 0 20px;
    }
    .message {
        margin: 20px 0 0 10px;
        padding: 0 10px;
    }
    .link_p {
        margin-top: 10px;
    }
}

.template_livreor {
    &:not(.template_livreor_2) {
        .message_lo {
            box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            &:hover {
                box-shadow: 1px 1px 20px rgba(0, 0, 0, .5);
            }
        }
    }
    &.template_livreor_1 {
        .message_lo h4,
        .message_lo .h4 {
            max-width: calc(100% - 70px);
            padding-left: 20px;
        }
    }
    &.template_livreor_2 {
        .message_lo {
            .note {
                border-radius: 0;
            }
            .contenuMsgLO {
                box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
                border-radius: 3px;
            }
            .IDLO {
                border-radius: 3px;
            }
            &:hover {
                .contenuMsgLO {
                    box-shadow: 1px 1px 20px rgba(0, 0, 0, .5);
                }
                .blcFleche {
                    top: 30px;
                }
            }
        }
    }
}

/*-----------------------------
FICHE PRODUOIT
-------------------------------*/

.cmonsite-ficheproduit {
    #bloc-fil-title {
        h1 {
            display: none;
        }
    }
}

.template_fiche_produit {
    margin-top: 20px;
    #fiche-produit {
        @include star('.remise-produit', 50px, 6px);
        .remise-produit {
            font-size: 13px;
        }
        .triangle-ficheprod{
            @include star('.remise-produit', 50px, 6px);
            .remise-produit {
                font-size: 13px;
            }
        }
        .triangle-ficheprod{
            z-index: 1001;
        }
        .previews {
            .next, .previous {
                opacity: .2;
            }
            &:hover {
                .next, .previous {
                    opacity: 1;
                }
                .next {
                    right: 10px;
                }
                .previous {
                    left: 10px;
                }
            }
        }
        .note-produit {
            > i, > a {
                display: inline-block;
                vertical-align: middle;
                float: none;
            }
            > a {
                text-decoration: none;
                margin-left: 5px;
            }
        }
        .bloc-quantite {
            label {
                margin: 0 5px 0 0;
            }
            .quantite {
                margin: 0;
                padding: 5px;
                border: 1px dotted rgba(0, 0, 0, .25);
                transition: all .2s linear;
                text-align: center;
                &:hover, &:focus {
                    border: 1px solid rgba(0, 0, 0, .5);
                }
            }
        }
        .prix {
            border-style: dotted none;
            text-align: center;
        }
        .ajouter-panier {
            position: relative;
        }
        .grande-image{
            z-index: auto;
        }
        .wrap-description {
            .h1, h1{
                margin-top:0;
            }
            .brand{
                margin-top:0;
                background:transparent !important;
                border:none !important;
            }
            .declinaison-attribute-group,.product_options-group {
                margin: 5px 0;
                label {
                    &, &:first-child {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 5px 0 0;
                        float: none;
                    }
                }
                .radio_unique, .radio_color {
                    border-radius: 3px;
                    border-width: 2px;
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
                    transition: all .2s linear;
                    &.unavailable-option {
                        opacity: .5;
                    }
                }
                .radio_color{
                    border: 1px solid rgba(#aaa, .4);
                }
                &:hover {
                    .radio_unique, .radio_color {
                        opacity: .5;
                        &:hover, &.unavailable-option:hover, &.radio-checked, &.radio-color-checked {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    &.template_fiche_produit_1 #fiche-produit {
        .remise-produit {
            right: 0;
            left: auto;
        }
    }
    &.template_fiche_produit_2 #fiche-produit,
    &.template_fiche_produit_3 #fiche-produit {
        .wrap-description {
            .declinaison-attribute-group,.product_options-group {
                label:first-child {
                    display: block;
                    margin-bottom: 5px;
                }
            }
            h1, .h1, .note-produit, .details-ficheprod {
                text-align: center;
            }
            .note-produit > a {
                font-size: 12px;
            }
            .addbasket {
                position: static;
                font-size: 1.2em;
                margin: 0 auto;
            }
            #produit-comment {
                > h4, > .h4, .noteGlobale {
                    text-align: center;
                }
                .pull-right, .pull-left {
                    float: none !important;
                }
            }
        }
        .ajouter-panier{
            &.block-addbasket{
                margin-top: 10px;
            }
        }
    }
    &.template_fiche_produit_3 #fiche-produit {
        .prix {
            width: 100%;
            float: none;
            display: block;
            clear: both;
            margin-top: 15px;
        }
        .bloc-quantite{
            margin-bottom: 15px;
            clear: both;
        }
        .remise-produit {
            right: 0;
            left: auto;
        }
        .prix {
            .prixprod, .oldprix {
                float: none;
                display: inline-block;
                width: auto;
            }
        }
        .details-descr-com {
            .side-tabs, .contents-tabs {
                width: 100%;
                float: none;
                clear: both;
            }
            .contents-tabs {
                margin-top: 20px;
            }
        }
        .wrap-description {
            .side-tabs > a {
                margin-top: 0;
            }
        }
    }
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
        .fa {
            margin-right: 10px;
        }
    }

    > .button + .button {
        margin-left: 15px;
    }

}

.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}


/*-----------------------------
PAGE DE CONTACT
-------------------------------*/

.form {
    border-radius: 3px 3px 3px 3px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    float: left;
    margin: 30px 0;
    position: relative;
    width: 100%;
    padding: 20px;
    transition: box-shadow 0.25s linear;
    h2, .h2 {
        border-bottom-style: dotted;
        border-bottom-width: 1px;
        border-radius: 3px 3px 0 0;
        margin: 0;
        padding: 10px 10px 10px 20px;
        width: auto;
    }
    fieldset {
        float: left;
        width: 220px;
        margin-right: 20px;
        margin-bottom: 20px;
        margin-left: 24px;
        &.last {
            margin-right: 0;
            width: 370px;
        }
    }
    p {
        margin: 10px 0 5px;
        &.userdigit {
            margin: 20px 0 5px;
            label {
                display: inline;
            }
            input {
                display: inline;
            }
            input[type=text] {
                width: auto;
            }
        }
    }
    label {
        display: block;
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

.col.btnCapValid {
    .col.s8 {
        clear: both;
        float: none;
    }
    .col.s4 {
        float: right;
    }
}

.template_contact {
    &.template_contact_1 {
        .inmap {
            margin-bottom: 15px;
        }
    }
    &.template_contact_2 {
        input[type="text"]:not(.button), textarea {
            background: rgba(255, 255, 255, .3);
            &:hover, &:focus {
                background: rgba(255, 255, 255, 1);
            }
        }
    }
    &.template_contact_3 {
        .form, address {
            margin-top: 20px;
            display: inline-block;
            width: 100%;
        }
        form{
            border-width: 0;
            box-shadow: none;
        }
        h2, .h2{
            text-align: center;
        }
    }
    &.template_contact_4 {
        .tmp4blocC, .tmp4blocM{
            padding:0;
            h2, .h2{
                margin: 0;
                box-sizing: border-box;
            }
        }
        .tmp4blocC{
            vertical-align: top;
            form{
                padding: 5px;
                margin: 0;
                border-width: 0;
                box-shadow: none;
            }
        }
    }
    &.template_contact_5 {
        .blocAdresse{
            //Pour passer au-dessus du js
            width:100% !important;
            margin-left:0 !important;
        }
    }
}

/* FOOTER
   ================================================== */

footer {
    position: relative;
    display: block;
    padding: 30px 20px 10px;
    width: 100%;
    clear: both;
    border-radius: 0 0 3px 3px;
    border-top-style: solid;
    border-top-width: 1px;
    #pub {
        text-align: center;
        margin: 0 0 30px;
    }
    .mention {
        text-align: center;
        display: block;
        margin: 5px 0 0;
        padding: 10px 0;
    }
    .music {
        text-align: center;
    }
}

/* PANIER
   ================================================== */

.template-panier{
    .connexion p {
        &, & a{
            color:inherit !important;
        }
    }
    #paniercontent{
        color: #000;
    }
    &.template-panier-2{
        width: 127px;
        margin:3px 3px 0 0;
        min-height: 36px;
        height: 36px;
        border: none;
        #panier{
            .paniertoggle{
                position:absolute;
                right: 0;
                top:0;
            }
        }
        #header-panier{
            font-size: 13px;
            .fa-shopping-cart{
                font-size: 13px;
            }
        }
        .count-live{
            left:17px;
            top:17px;
        }
    }
    &.template-panier-3{
        width: 100%;
        top:210px;
        border:none;
        .connexion p a, #header-panier, #header-panier .fa-shopping-cart{
            font-size: .8rem;
            line-height: 1.9rem;
        }
        #header-panier{
            padding:5px 10px;
        }
        .paniertoggle{
            position: absolute;
            top:0;
            right: 0;
            width: 30px;
            height: 100%;
            > i{
                line-height: 40px;
                width: 30px;
                text-align: center;
            }
        }
        #paniercontent{
            right: auto;
            left: 100%;
            top: 0;
            z-index: 10000;
        }
    }
    &.template-panier-4{
        width: 100%;
        margin-top: 1px;
        .connexion, #panier{
            width: 50%;
            box-sizing: border-box;
            margin: 0;
            p{
                line-height: initial;
            }
        }
        .connexion{
            border-right:1px dotted rgba(0,0,0,.1);
        }
        #panier{
            position: relative;
            .fa-shopping-cart{
                margin-right:0;
            }
            .paniertoggle{
                position: absolute;
                top:0;
                right:0;
                height: 100%;
            }
        }
        #paniercontent{
            right: auto;
            left: 100%;
            top: 0;
        }
        #header-panier{
            .count-live{
                top:5px;
                bottom:auto;
                right: auto;
                left: 50%;
                margin-left:5px;
            }
        }
    }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    padding-top: 20px;

    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 95%;
    }

    .side-tabs.etapes {
        padding-bottom: 15px;
    }

    .logo-carrier-none {
        width: auto;
    }

    h4 {
        margin: 0;
    }

    div#resume .block-resume-price .col.s6,
    div#finish .block-resume-price .col.s6 {
        width: 100%;
    }

    div#resume .block-price .block-with-background,
    div#finish .block-price .block-with-background {
        border-left: none;
    }

    #information #infoscommande .pull-right {
        /*Hack pour passer au dessus du important style all design, pour assurer un rendu propre*/
        float: none !important;
    }

    #connexion-inscription > .col.s6 {
        padding: 0;
        width: 100%;
        margin: 15px 0;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

body .template_flux_panier_2{
    .block-resume, .block-information, .recapitulatif.recap-l, .recapitulatif.recap-r{
        width: 100%;
    }
    .form-order::before, .block-finish::before{
        display: none;
    }
    #resume, .recapitulatif.recap-l{
        background: rgba(0,0,0,0.02);
    }
}

/* Commandes */

#order .commandes td{
    padding:10px 0;
    .button{
        margin: 0 5px 0 0 !important;
    }
}
#order .commandes-details{
    > td > div{
        border-bottom:none;
    }
    .infosclient.s6{
        &, &:first-child{
            width: 100%;
            margin:10px 0 0;
        }
    }
}

/* MEDIA QUERIES
   ================================================== */

//Réduit un peu la largeur du bloc principal pour ne pas voir un menu d�roulant en bas avec l'icone de recherche
@media screen and (max-width: 1080px) {
    #main-conteneur {
        width: 950px;
    }
    .reservation__date {
        .datepicker__month {
            width: 100%;
            &:nth-child(n+2){
                margin-top: 20px;
            }
        }
    }
    .reservation__date .datepicker__months{
        max-height:none;
    }
    .reservation__date .datepicker__months::before{
        display:none;
    }
}

@media #{$large-and-up} {
    .menu_burger {
        display: none;
    }
}

@media #{$medium-and-down} {
    //Produits slider accueil
    .template_boutique_accueil {
        &.accueil_boutique_template_1 {
            @include produitAccueilTaille(70px, 2, 20px);
        }
    }
    .wrapper-content::before{
        display: none;
    }
    //Tout mis sur une colonne
    #main-conteneur {
        width: $small-screen - 40px;
    }
    #nav-principal, .wrapper-content .wrapper, .wrapper-content .accueil_diaporama_template, .menu-principal.navigation_p, .wrapper-content::after{
        width: 100% !important;
    }

    .wrapper-content .wrapper, .wrapper-content .accueil_diaporama_template{
        border-left:none;
    }

    .wrapper-content > .menu-secondary{
        width: 100%;
    }
    #nav-secondary, .cmonsite-panier-3 #nav-secondary {
        margin:0;
        width:100%;
        ul{
            display: none;
        }
        .template-panier-4{
            #paniercontent{
                right:0;
                top:100%;
                left:auto;
                width: 200%;
            }
        }
    }

    .template-panier{
        &.template-panier-2{
            width: 100%;
            min-height: 0;
            height: 0;
            margin: 0;
            top:46px;
            #header-panier{
                top:-46px;
                height: 44px;
                line-height: 25px;
                font-size: 0;
                .fa-shopping-cart{
                    font-size: 22px;
                }
                .count-live{
                    left:25px;
                    top:10px;
                }
            }
            #paniercontent{
                width: 100%;
                max-width: 350px;
                min-width: 0;
            }
        }
        &.template-panier-3{
            position: relative;
            top: 0;
            float: none;
            #header-panier{
                text-align: center;
                .count-live{
                    left:50%;
                    margin-left:-25px;
                }
            }
            #paniercontent{
                width: 200%;
                left:auto;
                right: 0;
                top:100%;
                max-width: 200%;
            }
        }
    }

    //Formulaire newsletter
    .form-newsletter {
        text-align: center;
        input[type=text] {
            box-sizing: border-box;
            width: 100%;
            text-align: center;
        }
        #newsletter_valid {
            float: none;
        }
    }

    //Inscription
    #connexion-inscription {
        form {
            padding: 0;
            margin-bottom: 20px;
        }
        h2, .h2 {
            padding-left: 15px;
        }
    }

    //Header
    // Header : retire float + absolute;
    #blocContent, #headercontent, .cmonsite-panier-3 #blocContent {
        position: relative;
        width: 100%;
        height: auto;
    }
    #headercontent {
        height: auto;
    }
    .slogan {
        text-align: center !important;
        font-size: 18px !important;
        width: 100% !important;
        position: static !important;
        height: auto !important;
        padding-bottom: 15px;
        margin-top: 15px;
        display: block;

    }
    .logo {
        position: static !important;
        display: block !important;
        margin: 0 auto !important;
        padding-top: 15px;
        height: auto !important;
        text-align: center !important;
        width: 100% !important;
        margin-top: 15px;
        img {
            max-height: 150px !important;
            width: auto !important;
            max-width: 100% !important;
            height: auto !important;
            position: static !important;

        }
    }

    // Autre
    //Suppression recherche nav
    #block-flottant-search {
        display: none;
    }

    //Menu responsive

    #nav-principal {
        float: none;
        > ul {
            display: none;
            padding: 0;
            border:none;
            position: absolute;
            width: 100%;
            > li {
                margin: 0;
            }
            ul {
                width: 100%;
            }
        }
        li {
            width: 100%;
            display: block;
        }
        a {
            font-size: 16px;
            text-align: center;
            padding: 12px 15px;
        }
        span.dropDown {
            width: 50px;
            &::before {
                text-align: center;
            }
        }
    }
    .sousmenu_fermer > ul {
        display: none;
    }
    .menu_burger {
        cursor: pointer;
        display: block;
        &::before {
            font-family: FontAwesome, sans-serif;
            content: "\f0c9";
            margin-right: 5px;
        }
    }
}

@media #{$small-and-down} {
    //Produit slider accueil
    .template_boutique_accueil {
        &.accueil_boutique_template_1 {
            @include produitAccueilTaille(70px, 1, 0px);
        }
    }
    #main-conteneur {
        width: 95%;
    }

    #minisite-sidebar{
        display: none;
    }

    // Tableaux responsive

    @import 'design/helper/responsive/mobile/mobile';

    //Templates
    .template_boutique {
        .template_boutique_5 .produits {
            .productContentWrap > .produit_galerie_border_p {
                width: 100%;
                float: none;
            }
            .container-etiquette {
                padding-top: 0;
                top: 0;
                h3, .h3 {
                    font-size: 15px;
                }
            }
            .productImageWrap {
                min-height: 0;
                margin-bottom: 0;
            }
            .addbasket, .hors-stock {
                width: 100%;
            }
        }
    }

    .template-panier{
        &.template-panier-3{
            max-width: 100%;
            position: relative;
            top: 0;
            float: none;
            #paniercontent{
                width: 100%;
                max-width: 100%;
            }
            .paniertoggle > .fa{
                position: static;
                margin-top:0;
            }
        }
    }
}

/* =============================================================================
   Print styles.
   Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: black !important;
        text-shadow: none !important;
        filter: none !important;
        -ms-filter: none !important;
    }
    /* Black prints faster: sanbeiji.com/archives/953 */
    a, a:visited {
        color: #444 !important;
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
        content: "";
    }
    /* Don't show links for images, or javascript/internal links */
    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    /* css-discuss.incutio.com/wiki/Printing_Tables */
    tr, img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    @page {
        margin: 0.5cm;
    }
    p, h2, .h2, h3, .h3 {
        orphans: 3;
        widows: 3;
    }
    h2, .h2, h3, .h3 {
        page-break-after: avoid;
    }
}